import './src/styles/global.scss';
import 'aos/dist/aos.css';
import AOS from 'aos';

export const onClientEntry = () => {
    AOS.init({
        duration: 1000
    });
};

